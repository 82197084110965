import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, enableBodyScroll, isBodyScrollDisabled } from 'helpers/scroll';
import Icon from 'components/Icon';
import canUseDOM from 'helpers/canUseDOM';

import styles from './styles.scss';

const disableScroll = () => !isBodyScrollDisabled() && disableBodyScroll();
const enableScroll = () => isBodyScrollDisabled() && enableBodyScroll();
const getMainContent = () => document.getElementById('main-content');
const blurMainContent = () => {
  const main = getMainContent();
  if (main) main.style.filter = 'blur(10px)';
};
const unBlurMainContent = () => {
  const main = getMainContent();
  if (main) main.style.filter = '';
};

const Modal = props => {
  const {
    bodyLock,
    blur,
    show = false,
    onClickClose,
    onExited,
    children,
    containerClassName,
    closeIconClassName
  } = props;

  useEffect(() => () => unBlurMainContent, []);
  useEffect(() => {
    if (bodyLock) {
      if (show) {
        disableScroll();
      } else {
        enableScroll();
      }
    }

    if (blur) {
      if (show) {
        blurMainContent();
      } else {
        unBlurMainContent();
      }
    }
  }, [show, bodyLock, blur]);

  if (!canUseDOM) return null;

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      timeout={300}
      classNames={styles.modal}
      unmountOnExit={true}
      onExited={onExited}>
      <section className={styles.modal}>
        <div className={`${styles.modal__container} ${containerClassName}`}>
          <Icon
            name="close"
            classes={`${styles.modal__close} ${closeIconClassName}`}
            onClick={onClickClose}
          />
          {children}
        </div>
      </section>
    </CSSTransition>,
    document.getElementById('modal-portal')
  );
};

export default Modal;
