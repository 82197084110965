import React, { useState } from 'react';
import { get } from 'helpers/utilities';
import Modal from 'components/Modal';
import MediaEmbed from 'components/MediaEmbed';

import styles from './styles.scss';

const getVideo = data =>
  get(data, 'videoType') === 'embedVideos'
    ? get(data, 'videoBackground')
    : get(data, 'videoAssetBackground[0]');

const ModalContent = props => {
  const { current, cards } = props;
  const card = cards[current];

  if (!card) return null;

  const video = getVideo(card);

  if (!video) return null;

  return (
    <div className={styles.modalContent}>
      <h2 dangerouslySetInnerHTML={{ __html: get(card, 'videoProjectName.content', '') }} />
      <MediaEmbed
        data={{
          caption: '',
          imageAsset: card.imageAsset || [{}],
          videoAsset: card.videoBackground,
          videoNaviteAsset: card.videoAssetBackground,
          videoType: card.videoType
        }}
        className={styles.modalContent__media}
      />
      <p
        className={styles.modalContent__description}
        dangerouslySetInnerHTML={{ __html: get(card, 'videoDescription.content') }}
      />
    </div>
  );
};

const Description = ({ clientName, projectDescriptionContent }) => {
  const clientNameSpan = clientName ? `<span><strong>${clientName}</strong></span>` : '';
  const content = [clientNameSpan, projectDescriptionContent].filter(item => item).join(' | ');
  return <div dangerouslySetInnerHTML={{ __html: `${content}` }} />;
};

const ProjectDescriptionList = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { cards, current, style, className } = props;

  return (
    <section className={`${styles.projectDescriptionList} ${className}`} style={style}>
      {cards.map((card, index) =>
        card ? (
          <div
            key={index}
            className={`${styles.projectDescription} ${
              index === current ? styles['projectDescription--show'] : ''
            }`}>
            <Description
              clientName={get(card, 'clientName', '')}
              projectDescriptionContent={get(card, 'projectDescription.content', '')}
            />

            {getVideo(card) ? (
              <button type="button" className={styles.cta} onClick={() => setIsModalOpen(true)}>
                {get(card, 'videoCtaCopy')}
              </button>
            ) : null}
          </div>
        ) : null
      )}
      <Modal
        show={isModalOpen}
        bodyLock={true}
        blur={true}
        onClickClose={() => setIsModalOpen(false)}
        containerClassName={styles.modalContainer}
        closeIconClassName={styles.closeIcon}>
        <ModalContent current={current} cards={cards} />
      </Modal>
    </section>
  );
};

export default ProjectDescriptionList;
