// Formats a given time in seconds into the presentation format '00:00:00'
const formatTime = (sec = 0) => {
  // Ensure a float
  let timeInSeconds = parseFloat(sec);

  // Ensure a number
  timeInSeconds = Number.isNaN(timeInSeconds) ? 0 : timeInSeconds;

  let hours = timeInSeconds / 3600;
  let minutes = (timeInSeconds % 3600) / 60;
  let seconds = timeInSeconds % 60;

  // Strip out decimals
  hours = Math.floor(hours);
  minutes = Math.floor(minutes);
  seconds = Math.floor(seconds);

  // prepend zero if < 10
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return Number(hours) ? [hours, minutes, seconds].join(':') : [minutes, seconds].join(':');
};

export default formatTime;
