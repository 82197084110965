import React from 'react';
import { get } from 'helpers/utilities';

import Image from 'components/Image';

import styles from './styles.scss';

const imageSizes = {
  mobile: 700 // Mobile image can go up to 700px width before going 2-column on tablet
};

const NetworkGrid = ({ networksTitle, description, networks }, ref) => (
  <section className="wrapper" ref={ref}>
    <div className={styles.container}>
      <header>
        <h2 dangerouslySetInnerHTML={{ __html: get(networksTitle, 'content', '') }} />
        <p>{description}</p>
      </header>

      <ul>
        {networks.map(network => (
          <li>
            <div>
              <div className={styles.logoWrapper}>
                <Image
                  src={get(network, 'image[0].url')}
                  alt={get(network, 'image[0].title')}
                  grayscale={true}
                  sizes={imageSizes}
                />
              </div>
              <p className={styles.description}>{network.description}</p>
            </div>

            <p
              className={styles.location}
              dangerouslySetInnerHTML={{ __html: get(network, 'locations.content') }}
            />
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default React.forwardRef(NetworkGrid);
