import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import Swipe from 'react-easy-swipe';
import { get } from 'helpers/utilities';
import { breakpoints } from 'config/constants';
import useBreakpoint from '../../hooks/useBreakpoint';
import Icon from '../Icon';

import styles from './styles.scss';

const breakpointSizes = {
  mobile: 250,
  tablet: 400,
  smallDesktop: 527,
  largeDesktop: 600
};

const OurLeadership = (props, ref) => {
  const { leaders } = props;

  const [length, setLength] = useState(9);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const breakpoint = useBreakpoint();

  const swipe = newIndex => {
    const indexOnStop = breakpoint === 'tablet' ? leaders.length - 1 : leaders.length;

    // Need to return if newIndex is greater than lenght of data or at 0
    if (newIndex > 0 || newIndex < indexOnStop * -1 + 1) {
      return;
    }

    setActiveSlideIndex(newIndex);
  };

  let inlineStyle =
    window.innerWidth >= breakpoints.tablet
      ? `translateX(calc((40% + 40px) * ${activeSlideIndex}))`
      : `translateX(calc((80% + 40px) * ${activeSlideIndex}))`;
  inlineStyle = window.innerWidth >= breakpoints.small_desktop ? '0' : inlineStyle;

  const leadersData =
    breakpoint === 'mobile' || breakpoint === 'tablet' ? leaders : leaders.slice(0, length);

  return (
    <section style={{ overflow: 'hidden' }} ref={ref}>
      <div className="wrapper">
        <div className={styles.container}>
          <header>
            <h2>
              <strong>Our</strong> Leadership
            </h2>
          </header>

          <Swipe
            onSwipeLeft={() => swipe(activeSlideIndex - 1)}
            onSwipeRight={() => swipe(activeSlideIndex + 1)}>
            <ul style={{ transform: inlineStyle }}>
              {leadersData.map((leader, index) => (
                <li key={index}>
                  <div className={styles.image}>
                    <Image
                      src={get(leader, 'profileImage[0].url')}
                      alt={get(leader, 'profileImage[0].title')}
                      grayscale={true}
                      sizes={breakpointSizes}
                    />
                  </div>
                  <h3 dangerouslySetInnerHTML={{ __html: get(leader, 'profileName.content') }} />
                  <p>/ {get(leader, 'position')}</p>
                  {get(leader, 'linkedInLink') && (
                    <Link
                      to={{ pathname: `${get(leader, 'linkedInLink')}` }}
                      className={styles.social}>
                      <Icon color="white" name="linkedin" classes={styles.socialIcon} />
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </Swipe>

          {length >= leaders.length ? null : (
            <button type="button" onClick={() => setLength(prevLength => prevLength + 9)}>
              Load More
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default React.forwardRef(OurLeadership);
