import React, { useState } from 'react';
import { get } from 'helpers/utilities';

const Slider = props => {
  const { children, infinite = false, total = 0 } = props;

  const [current, setCurrent] = useState(0);

  function next() {
    if (!total) return;

    if (infinite) setCurrent((current + 1) % total);
    else setCurrent(current < total - 1 ? current + 1 : current);
  }

  function prev() {
    if (!total) return;

    if (infinite) setCurrent(current === 0 ? total - 1 : current - 1);
    else setCurrent(current > 0 ? current - 1 : current);
  }

  return children({ current, next: () => next(), prev: () => prev(), setCurrent });
};

export const withSlider = Component => props =>
  (
    <Slider total={get(props, 'total', 0)} infinite={get(props, 'infinite', false)}>
      {sliderProps => <Component {...props} {...sliderProps} />}
    </Slider>
  );

export default Slider;
