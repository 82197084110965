import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'helpers/utilities';

// components
import Image from 'components/Image';

import styles from './styles.scss';

const imageSizes = {
  smallDesktop: 500
};

const OfficeGrid = ({ locations }, ref) => {
  const onEmailClick = () => {
    window.ignore_unload = true;
  };

  return (
    <section className={styles.container} ref={ref}>
      <header>
        <h2>
          <strong>Our</strong> Offices
        </h2>
        <p>We have locations around the world.</p>
      </header>

      <ul>
        {locations.map((location, index) => {
          const { emailAddress, phoneNumber } = get(
            location,
            'officeLocation[0].officeLocation[0]',
            {}
          );

          return (
            <li key={index}>
              <h3>
                <Link to={`/location/${get(location, 'officeLocation[0].slug')}`}>
                  {get(location, 'locationFullname')}
                </Link>
              </h3>
              <div className={styles.infoWrapper}>
                <p className={styles.contact}>
                  <span>{phoneNumber}</span>
                  <span>
                    <a href={`mailto:${emailAddress}`} onClick={() => onEmailClick()}>
                      {emailAddress}
                    </a>
                  </span>
                </p>
                <div className={styles.image}>
                  <Image
                    src={get(location, 'locationImage[0].url')}
                    alt={get(location, 'locationImage[0].title')}
                    grayscale={true}
                    sizes={imageSizes}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default React.forwardRef(OfficeGrid);
