import React from 'react';
import { get } from 'helpers/utilities';
import { Link } from 'react-router-dom';
import Parallax from 'components/Parallax';
import { stringToBold, slugify } from 'helpers/text';
import useGreenHouseJobs from '../../hooks/useGreenHouseJobs';

import styles from './styles.scss';

function createURLParam(location) {
  const locVariable = `?location=${slugify(location)}`;
  return locVariable;
}

const filterFeaturedJobs = (greenHouseJobs = []) =>
  greenHouseJobs.filter(({ metadata }) => {
    const featuredMeta = metadata.find(({ name }) => /featured/i.test(name));
    return featuredMeta && featuredMeta.value;
  });

const formatGreenHouseJobs = (greenHouseJobs = []) =>
  greenHouseJobs.map(job => {
    const locationName = get(job, 'offices[0].name');
    const jobLinkTitle = job.title;
    const jobLink = `/careers/${job.id}${createURLParam(locationName)}`;

    return {
      jobLink,
      jobLinkTitle
    };
  });

const parseFeaturedJobs = (greenHouseJobs = []) => {
  // Filter out featured jobs
  const featuredJobs = filterFeaturedJobs(greenHouseJobs);
  // Format featured jobs
  const featuredJobsData = formatGreenHouseJobs(featuredJobs);

  return featuredJobsData;
};

const JoinOurTeam = ({ joinOurTeamTitle, description, jobPostingCta, backgroundImage }, ref) => {
  const jobs = useGreenHouseJobs();
  const { text, pageLink } = get(jobPostingCta, '[0]');

  const featuredJobs = parseFeaturedJobs(jobs);

  return (
    <section className={`component ${styles['join-our-team']}`} ref={ref}>
      <Parallax
        imageURL={get(backgroundImage, '[0]url')}
        imageAlt={get(backgroundImage, '[0]title')}
        className={styles['join-our-team__background']}
        grayscale={true}
      />
      <section className={`${styles['join-our-team__content']} wrapper`}>
        <div className={`${styles['join-our-team__heading']}`}>
          {joinOurTeamTitle ? (
            <h2
              className={styles['join-our-team__title']}
              dangerouslySetInnerHTML={{ __html: stringToBold(get(joinOurTeamTitle, 'content')) }}
            />
          ) : null}
          <div className={styles['join-our-team__description']}>{description}</div>
          <span>
            <Link
              to={{ pathname: `/${get(pageLink, '[0].uri')}` }}
              className={styles['join-our-team__link']}
              title={text}>
              {text}
            </Link>
          </span>
        </div>

        <ul className={styles['join-our-team__jobs']}>
          {featuredJobs.map(({ jobLink, jobLinkTitle }, index) => (
            <li key={index}>
              <Link to={jobLink} title={jobLinkTitle}>
                {jobLinkTitle}
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </section>
  );
};

export default React.forwardRef(JoinOurTeam);
