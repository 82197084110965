import pageHeaderMediaQuery from './pageHeaderMedia';
import contactModule from './contactModule';
import metaDetails from './metaDetails';
import locationQuery from './locationQuery';
import cardQueries from './cardQueries';

export default `
    ... on AboutUs {
        aboutUsBody {
            __typename
            ... on AboutUsBodyAboutUsHeader {
                headerTitle {
                    content
                }
                ${pageHeaderMediaQuery}
                links {
                    linkTitle
                    linkDescription
                    linkTo
                }
            }
            ... on AboutUsBodyAgencyDescription {
                agencySections {
                    agencyTitle {
                        content
                    }
                    description
                    image {
                        url
                        title
                    }
                }
            }
            ... on AboutUsBodyJoinOurTeam {
                joinOurTeamTitle {
                    content
                }
                description
                jobPostingCta {
                    text
                    pageLink {
                        uri
                    }
                }
                backgroundImage {
                    url
                    title
                    extension
                }
            }
            ... on AboutUsBodyCulture {
                sections {
                    cultureTitle {
                        content
                    }
                    bigImage {
                        url
                        title
                    }
                    smallImage {
                        url
                        title
                    }
                    description
                    alignment
                }
            }
            ... on AboutUsBodyLeadership {
                leaders {
                    ... on LeadersSuperTableBlockType {
                        profileImage {
                            url
                            title
                            mimeType
                        }
                        profileName {
                            content
                        }
                        position
                        linkedInLink
                    }
                }
            }
            ... on AboutUsBodyStagwell {
                stagwellCopy {
                    content
                }
            }
            ... on AboutUsBodyOffices {
                officesTitle {
                    content
                }
                description
                locations {
                    locationImage {
                        url
                        title
                        mimeType
                    }
                    locationFullname
                    officeLocation {
                        ... on Location {
                            slug
                            ${locationQuery}
                        }
                    }
                }
            }
            ... on AboutUsBodyServices {
                servicesTitle {
                    content
                }
                description
                services {
                    serviceTitle {
                        content
                    }
                    serviceDescription
                }
            }
            ... on AboutUsBodyNetworks {
                networksTitle {
                    content
                }
                description
                networks {
                    image {
                        url
                        title
                    }
                    description
                    locations {
                        content
                    }
                }
            }
            ... on AboutUsBodyServicesCards {
                eyebrow
                moduleTitle {
                  content
                }
                servicesCards {
                    serviceEntry {
                        title
                        uri
                        ${cardQueries.services}
                    }
                    serviceDescription {
                        content
                    }
                    serviceVideoName {
                        content
                    }
                    imageAsset {
                        title
                        url
                    }
                    videoType
                    videoBackground {
                        title
                        url
                    }
                    videoAssetBackground {
                        title
                        url
                    }
                    videoCtaCopy
                    videoDescription {
                        content
                    }
                }
            }
        }
        ${contactModule}
        ${metaDetails}
    }
`;
