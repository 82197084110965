import React, { useRef, useCallback } from 'react';
import Swipe from 'react-easy-swipe';
import { struct } from 'superstruct';

import { get } from 'helpers/utilities';
import { wysiwygStruct } from 'helpers/structure';

import safeRender from 'components/hocs/safeRender';
import Card from 'components/Cards';
import ProjectDescriptionList from 'components/ProjectSlider/ProjectDescriptionList';
import { withSlider } from 'components/Slider';

import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const parseAboutUsPackageCard = card => {
  const cardProject = [
    {
      ...get(card, 'serviceEntry[0].cardProject[0]', {}),
      caption: ''
    }
  ];

  return {
    uri: get(card, 'serviceEntry[0].uri'),
    cardProject,
    isExternalLink: false,
    imageAsset: card.imageAsset,
    projectDescription: card.serviceDescription,
    videoAssetBackground: card.videoAssetBackground,
    videoBackground: card.videoBackground,
    videoCtaCopy: card.videoCtaCopy,
    videoDescription: card.videoDescription,
    videoProjectName: card.videoProjectName,
    videoType: card.videoType
  };
};

const CardList = withSlider(props => {
  const direction = useRef({ value: 1 });
  const scrollDirection = useRef({ value: '' });
  const cardRefs = useRef([]);
  const breakpoint = useBreakpoint();

  const { cards, current, setCurrent, total, next, prev, variant } = props;
  const lineOffsetLeft = get(cardRefs.current[current], 'offsetLeft', 0);

  const onSwipeStart = useCallback(() => {
    if (breakpoint !== 'mobile') return false;

    cardRefs.current.forEach(el => {
      if (!el) return;
      el.style.transition = '';
    });
  }, [breakpoint]);

  const onSwipeMove = useCallback(
    e => {
      if (breakpoint !== 'mobile') return false;

      const { current: directionCurrent } = direction;
      const { current: scrollDirectionCurrent } = scrollDirection;

      directionCurrent.value = e.x < 0 ? -1 : 1;

      if (!scrollDirectionCurrent.value) {
        // threshold of 5 to determine
        // what scroll direction
        if (e.y > 5 || e.y < -5) {
          scrollDirectionCurrent.value = 'vertical';
        } else {
          scrollDirectionCurrent.value = 'horizontal';
        }
      }

      const isOnEnd =
        (directionCurrent.value === -1 && current === total - 1) ||
        (directionCurrent.value === 1 && current === 0);
      if (scrollDirectionCurrent.value === 'horizontal' && !isOnEnd) {
        cardRefs.current.forEach((el, index) => {
          if (!el) return;
          el.style.transform = `translateX(${(index - current) * 100}%) translateX(${
            (index - current) * 24
          }px) translateX(${e.x}px)`;
        });
        return true;
      }

      return false;
    },
    [current, breakpoint, total]
  );

  const onSwipeEnd = useCallback(() => {
    if (breakpoint !== 'mobile') return false;

    const { current: directionCurrent } = direction;
    const { current: scrollDirectionCurrent } = scrollDirection;

    if (scrollDirectionCurrent.value === 'horizontal') {
      cardRefs.current.forEach(el => {
        if (!el) return;
        el.style.transition = 'opacity 300ms ease-in-out, transform 300ms ease-in-out';
      });
      if (directionCurrent.value === -1) next();
      else prev();
    }
    scrollDirectionCurrent.value = '';
  }, [breakpoint, next, prev]);

  return (
    <>
      <Swipe
        className={`${styles.cardList} ${styles[`cardList--${variant}`]}`}
        onSwipeStart={e => onSwipeStart(e)}
        onSwipeMove={e => onSwipeMove(e)}
        onSwipeEnd={e => onSwipeEnd(e)}>
        {cards.map((cardData, index) => {
          if (!cardData) return null;
          return (
            <Card
              key={index}
              size="standard"
              className={styles.cardList__card}
              title={cardData.title}
              uri={cardData.uri}
              isExternalLink={cardData.isExternalLink}
              cardCurated={cardData.cardProject}
              active={current === index}
              zoom={true}
              onHover={() => setCurrent(index)}
              style={{
                transition: 'opacity 300ms ease-in-out, transform 300ms ease-in-out',
                opacity: current === index ? 1 : 0.4,
                transform:
                  breakpoint === 'mobile'
                    ? `translateX(${(index - current) * 100}%) translateX(${
                        (index - current) * 24
                      }px)`
                    : ''
              }}
              ref={el => (cardRefs.current[index] = el)}
            />
          );
        })}
      </Swipe>
      <div className={styles.line}>
        <div style={{ left: lineOffsetLeft }} />
      </div>
      <ProjectDescriptionList
        cards={cards}
        current={current}
        className={styles.projectDescriptionList}
      />
    </>
  );
});

const AboutUsPackageCards = ({ data }, ref) => {
  const { eyebrow, moduleTitle, servicesCards } = data;

  const cards = servicesCards.map(card => parseAboutUsPackageCard(card));

  return (
    <section className={styles.packageCards} ref={ref}>
      {eyebrow ? <p className={styles.eyebrow}>{eyebrow}</p> : null}
      <h2
        className={styles.moduleTitle}
        dangerouslySetInnerHTML={{ __html: get(moduleTitle, 'content', '') }}
      />
      <CardList cards={cards} total={cards.length} />
    </section>
  );
};

const packageCardsStruct = struct.partial({
  moduleTitle: wysiwygStruct,
  servicesCards: struct.list([
    struct.partial({
      serviceEntry: struct.tuple(['any'])
    })
  ])
});

export default safeRender(packageCardsStruct)(React.forwardRef(AboutUsPackageCards));
