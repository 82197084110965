import React, { useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { get } from 'helpers/utilities';
import Image from 'components/Image';
import useBreakpoint from '../../hooks/useBreakpoint';
import useCombinedRefs from '../../hooks/useCombinedRefs';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const bigImageSizes = {
  mobile: 400,
  tablet: 500,
  smallDesktop: 600
};

const smallImageSizes = {
  mobile: 165,
  tablet: 250,
  smallDesktop: 300
};

const AgencyDescriptionWithTwoImages = (
  { cultureTitle, description, bigImage, smallImage, alignment },
  ref
) => {
  const containerRef = useCombinedRefs(ref);
  const bigImageRef = useRef();
  const smallImageRef = useRef();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    const activateTransition = () => {
      const containerScrollPos = containerRef.current.getBoundingClientRect().top;
      const { innerHeight } = window;
      let translate;

      /**
       * get the top value of container per scroll and divide to inner height of the viewport
       */
      const scale = containerScrollPos / innerHeight;

      if (scale >= 0 && scale <= 1) {
        translate = scale * 10;
      }

      bigImageRef.current.style.transform = `translateY(${translate}%)`;
      smallImageRef.current.style.transform = `${
        breakpoint === 'smallDesktop' && alignment === 'right' && 'translateX(-100%)'
      } translateY(${translate}%)`;
    };

    window.addEventListener('scroll', activateTransition);
    return () => window.removeEventListener('scroll', activateTransition);
  }, []);

  const containerClass = cx('container', 'Culture', {
    right: alignment === 'right'
  });

  return (
    <section className={containerClass} ref={containerRef}>
      <header>
        <h2 dangerouslySetInnerHTML={{ __html: get(cultureTitle, 'content') }} />
        <p>{description}</p>
      </header>
      <aside>
        <div className={styles.imageWrapper}>
          <div className={styles.image1} ref={bigImageRef}>
            <Image
              src={get(bigImage, '[0]url')}
              alt={get(bigImage, '[0]title')}
              grayscale={true}
              sizes={bigImageSizes}
            />
          </div>

          {get(smallImage, '[0]url') ? (
            <div className={styles.image2} ref={smallImageRef}>
              <Image
                src={get(smallImage, '[0]url')}
                alt={get(smallImage, '[0]title')}
                grayscale={true}
                sizes={smallImageSizes}
              />
            </div>
          ) : null}
        </div>
      </aside>
    </section>
  );
};

export default React.forwardRef(AgencyDescriptionWithTwoImages);
