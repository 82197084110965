import React, { useState } from 'react';
import Swipe from 'react-easy-swipe';
import { get } from 'helpers/utilities';
import { breakpoints } from 'config/constants';

import styles from './styles.scss';

const AboutUsServicesGrid = ({ servicesTitle, description, services }, ref) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const lastNumberofItems = window.innerWidth >= breakpoints.tablet ? 2 : 1;
  let inlineStyle =
    window.innerWidth >= breakpoints.tablet
      ? `translateX(calc((40%) * ${activeSlideIndex}))`
      : `translateX(calc((80%) * ${activeSlideIndex}))`;
  inlineStyle = window.innerWidth <= breakpoints.smallDesktop ? { transform: inlineStyle } : null;

  return (
    <section className={styles.container} ref={ref}>
      <header>
        <h2 dangerouslySetInnerHTML={{ __html: get(servicesTitle, 'content') }} />
        <p>{description}</p>
      </header>

      <Swipe
        onSwipeLeft={() =>
          Math.abs(activeSlideIndex) < services.length - lastNumberofItems
            ? setActiveSlideIndex(prevActiveSlideIndex => prevActiveSlideIndex - 1)
            : false
        }
        onSwipeRight={() =>
          activeSlideIndex < 0
            ? setActiveSlideIndex(prevActiveSlideIndex => prevActiveSlideIndex + 1)
            : false
        }>
        <ul style={inlineStyle} className={styles.gridWrapper}>
          {services.map((service, index) => (
            <li className={styles.gridItem} key={`service-item-${index}`}>
              <h4 dangerouslySetInnerHTML={{ __html: get(service, 'serviceTitle.content') }} />
              <p>{get(service, 'serviceDescription')}</p>
            </li>
          ))}
        </ul>
      </Swipe>
    </section>
  );
};

export default React.forwardRef(AboutUsServicesGrid);
