import React from 'react';
import { get } from 'helpers/utilities';

import BackgroundImage from '../BackgroundImage';

import styles from './styles.scss';

const AgencyDescriptionWithImage = ({ agencyTitle, description, image }, ref) => (
  <section className={styles.container} ref={ref}>
    <header>
      <h2 dangerouslySetInnerHTML={{ __html: get(agencyTitle, 'content', '') }} />
      <p>{description}</p>
    </header>
    <aside>
      <BackgroundImage src={get(image, '[0].url')} />
    </aside>
  </section>
);

export default React.forwardRef(AgencyDescriptionWithImage);
