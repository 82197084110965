export default `
    officeLocation {
        addressLine1
        addressLine2
        city
        state
        zip
        phoneNumber
        emailAddress
        mapUrl
    }
`;
