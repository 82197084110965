import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames/bind';
import { get, partition, flatten } from 'helpers/utilities';
import deviceInfo from 'helpers/deviceInfo';

// components
import Icon from 'components/Icon';
import Accordion from 'components/Accordion';
import { recursiveScrollTo } from 'helpers/scroll';
import useTimeout from '../../hooks/useTimeout';

// hooks
import useBreakpoint from '../../hooks/useBreakpoint';
import useNavigation from '../../hooks/useNavigation';

// helpers

import styles from './styles.scss';

const cx = classNames.bind(styles);

export const linkMap = {
  AboutUsBodyAgencyDescription: 'agencyDescription',
  AboutUsBodyCulture: 'culture',
  AboutUsBodyOffices: 'offices',
  AboutUsBodyJoinOurTeam: 'joinOurTeam',
  AboutUsBodyLeadership: 'leadership',
  AboutUsBodyServices: 'services',
  AboutUsBodyNetworks: 'networks'
};

export const componentMap = {
  AboutUsBodyLeadership: 'OurLeadership',
  AboutUsBodyJoinOurTeam: 'JoinOurTeam',
  AboutUsBodyCulture: 'AboutUsContainer__agencyCulture',
  AboutUsBodyOffices: 'OfficeGrid',
  AboutUsBodyServices: 'AboutUsServicesGrid',
  AboutUsBodyNetworks: 'NetworkGrid',
  AboutUsBodyAgencyDescription: 'AgencyDescriptionWithImage'
};

const onSubNavLinkClick = ({
  linkTo,
  timeout,
  setNavToggling,
  setNavVisible,
  setAccordionActive
}) => {
  const typename = get(
    Object.keys(linkMap).filter(key => linkMap[key] === linkTo),
    '[0]'
  );
  if (!typename || !componentMap[typename]) return;
  // not react way, will revisit this if still have time
  const element = document.querySelector(`[class*="${componentMap[typename]}"]`);

  if (element) {
    // hide navigation
    setNavVisible(false);
    setNavToggling(false); // disable nav tooglin
    setAccordionActive(false);
    // use request animation frame on scroll
    // instead of window.scroll to because the
    // height of about us body is changing due
    // to lazy loaded images
    recursiveScrollTo(element, 80, 0, () => {
      // enable nav toogling after scroll animation
      setNavVisible(false);
      timeout(() => setNavToggling(true), 500);
    });
  }
};

const sortSubNavLinks = ({ links, intersectedComponent, accordionActive, isMobile }) => {
  const activeLink = linkMap[intersectedComponent];
  if (isMobile && !accordionActive) {
    return flatten(partition(links, link => link.linkTo === activeLink));
  }
  return links;
};

const AboutUsSubNavigation = ({
  location,
  links,
  isTransporter,
  setIntersectedComponent,
  intersectedComponent,
  setSubNavVisibility,
  subNavVisibility
}) => {
  const [accordionActive, setAccordionActive] = useState(false);
  const breakpoint = useBreakpoint();
  const timeout = useTimeout();
  const {
    state: { visible: navVisible },
    setNavToggling,
    setNavVisible
  } = useNavigation();
  const isMobile = breakpoint === 'mobile' || breakpoint === 'tablet';

  useEffect(() => {
    const { state = {} } = location;
    const { componentLink } = state;

    if (componentLink) {
      timeout(() =>
        onSubNavLinkClick({
          linkTo: componentLink,
          timeout,
          setNavToggling,
          setNavVisible,
          setAccordionActive
        })
      );
    }
  }, [location.key]);

  useEffect(() => () => setSubNavVisibility(false), []);

  useEffect(() => {
    setIntersectedComponent(null);
  }, [location.pathname]);

  const subNavigationClass = cx('sub-navigation', {
    'sub-navigation--show': subNavVisibility && !navVisible, // when main nav is shown
    'sub-navigation--sticked': subNavVisibility, // when sub nav is sticked
    'sub-navigation--open': isMobile && accordionActive, // when sub nav is open
    'sub-navigation--is-transporter': !deviceInfo.isIOS && !deviceInfo.isSafari && isTransporter,
    'sub-navigation--is-transporter--show':
      !deviceInfo.isIOS && !deviceInfo.isSafari && subNavVisibility && isTransporter && !navVisible,
    'sub-navigation--is-transporter--android':
      deviceInfo.isAndroid && subNavVisibility && isTransporter && !navVisible
  });

  const activeLink = linkMap[intersectedComponent];

  const chevronClass = cx('chevron', {
    'chevron--rotated': isMobile && accordionActive
  });

  return (
    <section className={`${subNavigationClass}`}>
      <Accordion
        className={styles['sub-navigation__container']}
        show={isMobile ? accordionActive : !subNavVisibility}
        height={isMobile ? 300 : 100}
        heightTo="60px">
        <nav className={styles['sub-navigation__list']} aria-label="About Us sub navigation">
          {sortSubNavLinks({
            links,
            intersectedComponent,
            accordionActive,
            isMobile
          }).map((link, index) => {
            const linkClass = cx('sub-navigation__link', {
              'sub-navigation__link--active': activeLink === get(link, 'linkTo')
            });

            return (
              <button
                className={linkClass}
                key={index}
                type="button"
                onClick={() =>
                  onSubNavLinkClick({
                    linkTo: link.linkTo,
                    timeout,
                    setNavVisible,
                    setNavToggling,
                    setAccordionActive
                  })
                }
                aria-label={`${get(link, 'linkTitle')} - ${get(link, 'linkDescription')} 0${
                  index + 1
                }`}>
                <p className={styles['sub-navigation__link__title']}>{get(link, 'linkTitle')}</p>
                <section className={styles['sub-navigation__link__content']}>
                  <p>{get(link, 'linkDescription')}</p>
                  <p>0{index + 1}</p>
                </section>
              </button>
            );
          })}
        </nav>
        {isMobile && (
          <Icon
            name="chevronThickDown"
            classes={chevronClass}
            onClick={() => setAccordionActive(active => !active)}
          />
        )}
      </Accordion>
    </section>
  );
};

export default withRouter(AboutUsSubNavigation);
