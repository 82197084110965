import React, { useEffect, useCallback, useState } from 'react';

import { get, filter } from 'helpers/utilities';

import AboutUsHeader from 'components/AboutUsHeader';
import AboutUsServicesGrid from 'components/AboutUsServicesGrid';
import AgencyDescriptionWithImage from 'components/AgencyDescriptionWithImage';
import AgencyDescriptionWithTwoImages from 'components/AgencyDescriptionWithTwoImages';
import OfficeGrid from 'components/OfficeGrid';
import NetworkGrid from 'components/NetworkGrid';
import OurLeadership from 'components/OurLeadership';
import JoinOurTeam from 'components/JoinOurTeam';
import StagwellCopy from 'components/StagwellCopy';
import AboutUsPackageCards from 'components/AboutUsPackageCards';
import AboutUsSubNavigation, {
  linkMap as subNavigationLinkMap
} from 'components/AboutUsSubNavigation';
import SentinelIntersect from 'components/SentinelIntersect';
import Intersect from 'components/Intersect';
import Contact from 'components/Contact';
import Meta from 'components/Meta';
import NavigationThemeSetter from 'components/NavigationThemeSetter';
import useContact from '../../hooks/useContact';
import useAboutUsContent from '../../hooks/useAboutUsContent';
import buildGTMData from '../../helpers/buildGTMData';

const AboutUsComponentSentinelIntersect = ({
  children,
  setIntersectedComponent,
  links = [],
  typename
}) =>
  links.some(link => subNavigationLinkMap[typename] === link.linkTo) ? (
    <SentinelIntersect
      config={{ threshold: [0, 1] }}
      onIntersection={intersected => intersected && setIntersectedComponent(typename)}>
      {children}
    </SentinelIntersect>
  ) : (
    children
  );

const AboutUsBody = ({ components, aboutUsHeaderLinks, setIntersectedComponent }) => {
  if (!components.length) {
    return null;
  }

  const aboutUsBodyComponents = components
    .map((component, index) => {
      const name = get(component, '__typename');
      switch (name) {
        case 'AboutUsBodyAgencyDescription':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              {component.agencySections.map((section, i) => (
                <AgencyDescriptionWithImage key={i} {...section} />
              ))}
            </AboutUsComponentSentinelIntersect>
          );
        case 'AboutUsBodyLeadership':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              <OurLeadership {...component} />
            </AboutUsComponentSentinelIntersect>
          );
        case 'AboutUsBodyJoinOurTeam':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              <JoinOurTeam {...component} />
            </AboutUsComponentSentinelIntersect>
          );
        case 'AboutUsBodyCulture':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              {component.sections.map((section, i) => (
                <AgencyDescriptionWithTwoImages key={i} {...section} />
              ))}
            </AboutUsComponentSentinelIntersect>
          );
        case 'AboutUsBodyStagwell':
          return <StagwellCopy key={index} content={get(component, 'stagwellCopy.content')} />;
        case 'AboutUsBodyOffices':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              <OfficeGrid {...component} />
            </AboutUsComponentSentinelIntersect>
          );
        case 'AboutUsBodyServices':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              <AboutUsServicesGrid key={index} {...component} />
            </AboutUsComponentSentinelIntersect>
          );
        case 'AboutUsBodyServicesCards':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              <AboutUsPackageCards key={index} data={component} />
            </AboutUsComponentSentinelIntersect>
          );
        case 'AboutUsBodyNetworks':
          return (
            <AboutUsComponentSentinelIntersect
              key={index}
              typename={get(component, '__typename', '')}
              links={aboutUsHeaderLinks}
              setIntersectedComponent={setIntersectedComponent}>
              <NetworkGrid key={index} {...component} />
            </AboutUsComponentSentinelIntersect>
          );
        default:
          return null;
      }
    })
    .filter(component => component);
  return aboutUsBodyComponents;
};

const AboutUs = ({ transporterHeadRef, active }) => {
  const aboutUsContent = useAboutUsContent();
  const contactData = useContact(aboutUsContent);
  const gtmData = buildGTMData(aboutUsContent);

  const [intersectedComponent, setIntersectedComponent] = useState(null);
  const [subNavVisibility, setSubNavVisibility] = useState(false);

  const onIntersection = useCallback(
    e => {
      if (e.isIntersecting && e.boundingClientRect.y >= 0) {
        setSubNavVisibility(false);
        setIntersectedComponent(null);
      }
    },
    [setSubNavVisibility, setIntersectedComponent]
  );

  useEffect(() => () => setIntersectedComponent(null), []);

  if (!aboutUsContent || !contactData) return null;

  const aboutUsBodyData = get(aboutUsContent, 'aboutUsBody', []);
  const aboutUsHeaderLinks = get(
    aboutUsBodyData.filter(
      component => get(component, '__typename') === 'AboutUsBodyAboutUsHeader',
      {}
    ),
    '[0]links',
    []
  );
  const aboutUsHeaderData = get(
    aboutUsBodyData.filter(
      component => get(component, '__typename') === 'AboutUsBodyAboutUsHeader',
      {}
    ),
    '[0]',
    {}
  );
  const metaDetails = {
    metaSection: get(aboutUsContent, 'socialMetaSection[0]'),
    entryTitle: get(aboutUsContent, 'title'),
    placeholderImage: get(
      filter(
        get(aboutUsContent, 'aboutUsBody', []),
        ({ __typename }) => __typename === 'AboutUsBodyAboutUsHeader'
      ),
      '[0]headerBackground[0].url'
    )
  };

  return (
    <>
      <Meta metaDetails={metaDetails} active={active} gtm={gtmData} />
      <NavigationThemeSetter
        navTheme="header"
        active={active}
        onIntersection={intersectionData => {
          if (intersectionData.sentinel === 'bottom')
            setSubNavVisibility(!intersectionData.intersected);
        }}>
        <Intersect
          config={{ threshold: [0, 1] }}
          onIntersection={e => onIntersection(get(e, '[0]', {}))}>
          <AboutUsHeader {...aboutUsHeaderData} />
        </Intersect>
        <AboutUsSubNavigation
          links={aboutUsHeaderLinks}
          isTransporter={Boolean(transporterHeadRef && transporterHeadRef.current)}
          active={active}
          subNavVisibility={subNavVisibility}
          setSubNavVisibility={setSubNavVisibility}
          intersectedComponent={intersectedComponent}
          setIntersectedComponent={setIntersectedComponent}
        />
      </NavigationThemeSetter>
      <NavigationThemeSetter navTheme="white">
        <AboutUsBody
          aboutUsHeaderLinks={aboutUsHeaderLinks}
          components={aboutUsBodyData}
          setIntersectedComponent={setIntersectedComponent}
        />
        <Contact {...contactData} />
      </NavigationThemeSetter>
    </>
  );
};

export default AboutUs;
