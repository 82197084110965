import React from 'react';

import styles from './styles.scss';

const StagwellCopy = ({ content }, ref) => (
  <section
    className={styles.stagwellCopy}
    ref={ref}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

export default React.forwardRef(StagwellCopy);
